import "./App.css";
import Duties from "./pages/Duties";
import About from "./pages/About";
import Skills from "./pages/Skills";
import Layout from "./components/Layout";
import Projects from "./pages/Projects";
import { LanguageContext } from "./utils";

const App = () => {
  return (
    <LanguageContext.Provider value={null}>
      <Layout>
        <Duties />
        <About />
        <Skills />
        <Projects />
      </Layout>
    </LanguageContext.Provider>
  );
};

export default App;
