import { Row, Col, Divider } from "antd";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();
  return (
    <Row
      gutter={{ xs: 8 }}
      justify="center"
      align="middle"
      className="about-intro"
    >
      <Divider orientation="left" plain>
        <h1 className="about-intro-title">{t("ABOUT ME")}</h1>
      </Divider>
      <Col>
        <p>
          {t(
            "I'm a front-end developer based in Côte d'Ivoire. I'm specialized in ReactJS.",
          )}
        </p>
        <p>
          {t(
            "I work for many companies remotely and make a lot of random projects because the web needs some many improvements.",
          )}
        </p>
        <p>
          {t(
            "My passion for web development pushes me to give the best of myself for each project, and to improve my knowledge in the field of web technologies keeping me regularly informed of the latest innovations.",
          )}
        </p>
      </Col>
    </Row>
  );
};

export default About;
