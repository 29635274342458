import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      "Welcome": "Welcome",
      "ABOUT ME": "ABOUT ME",
      "SKILLS": "SKILLS",
      "SIDE PROJECTS": "SIDE PROJECTS",
      "English resume": "English resume",
      "French resume": "French resume",
      "Frontend Development": "Frontend Development",
      "Desktop Development": "Desktop Development",
      "Software Developer": "Software Developer",
      "Hi. I'm Emmanuel Dalougou": "Hi. I'm Emmanuel Dalougou",
      "My passion for web development pushes me to give the best of myself for each project, and to improve my knowledge in the field of web technologies keeping me regularly informed of the latest innovations.":
        "My passion for web development pushes me to give the best of myself for each project, and to improve my knowledge in the field of web technologies keeping me regularly informed of the latest innovations.",
      "I'm a front-end developer based in Côte d'Ivoire. I'm specialized in ReactJS.":
        "I'm a front-end developer based in Côte d'Ivoire. I'm specialized in ReactJS.",
      "I develop clear, fast and easy-to-use interfaces with ReactJS library, HTML5/CSS3 and bootstrap. My favorite frameworks is ReactJS.":
        "I develop clear, fast and easy-to-use interfaces with ReactJS library, HTML5/CSS3 and bootstrap. My favorite frameworks is ReactJS.",
      "I work for many companies remotely and make a lot of random projects because the web needs some many improvements.":
        "I work for many companies remotely and make a lot of random projects because the web needs some many improvements.",
        "With Electron, I build desktop application for the companies. Initially developed for GitHub's Atom editor." : "With Electron, I build desktop application for the companies. Initially developed for GitHub's Atom editor."
    },
  },
  fr: {
    translation: {
      "Welcome": "Bienvenue",
      "ABOUT ME": "À PROPOS DE MOI",
      "SKILLS": "COMPÉTENCES",
      "SIDE PROJECTS": "PROJETS PERSONNELS",
      "French resume": "CV français",
      "English resume": "CV anglais",
      "Desktop Development": "Développement de logiciels desktop",
      "Frontend Development": "Développement front-end",
      "Software Developer": "Développeur de logiciels",
      "Hi. I'm Emmanuel Dalougou": "Salut. Je suis Emmanuel Dalougou",
      "My passion for web development pushes me to give the best of myself for each project, and to improve my knowledge in the field of web technologies keeping me regularly informed of the latest innovations.":
        "Ma passion pour le développement web me pousse à donner le meilleur de moi-même pour chaque projet, et à approfondir mes connaissances dans le domaine des technologies web en me tenant régulièrement informé des dernières innovations.",
      "I'm a front-end developer based in Côte d'Ivoire. I'm specialized in ReactJS.":
        "Je suis un développeur front-end basé en Côte d'Ivoire. Je suis spécialisé en ReactJS",
      "I develop clear, fast and easy-to-use interfaces with ReactJS library, HTML5/CSS3 and bootstrap. My favorite frameworks is ReactJS.":
        "Je développe des interfaces claires, rapides et faciles à utiliser avec la bibliothèque ReactJS, HTML5/CSS3 et bootstrap. Mon framework préféré est ReactJS.",
      "I work for many companies remotely and make a lot of random projects because the web needs some many improvements.":
        "Je travaille pour de nombreuses entreprises à distance et je fais beaucoup de projets aléatoires car le web a besoin de nombreuses améliorations.",
        "With Electron, I build desktop application for the companies. Initially developed for GitHub's Atom editor.":"Avec Electron, je crée des applications de bureau pour les entreprises. Initialement développé pour l'éditeur Atom de GitHub."
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
