import { Row, Col, Avatar, Space, Divider } from "antd";
import { skills } from "../data";
import { useTranslation } from "react-i18next";

const Skills = () => {
  const { t } = useTranslation();
  return (
    <Row
      gutter={{ xs: 8 }}
      justify="center"
      align="middle"
      className="about-intro"
    >
      <Divider orientation="center" plain>
        <h1 className="about-intro-title">{t("SKILLS")}</h1>
      </Divider>
      <Col span={24} className="skill-icon">
        <Space>
          {skills.map((icon) => (
            <Avatar
              key={icon.id}
              src={icon.url}
              alt={icon.name}
              shape="square"
              size={85}
              draggable={true}
            />
          ))}
        </Space>
      </Col>
    </Row>
  );
};

export default Skills;
