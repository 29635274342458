import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { duties } from "../data";
const Duties = () => {
  const { t } = useTranslation();
  return (
    <Row
      gutter={{ xs: 8 }}
      justify="center"
      align="middle"
      className="_duties-intro"
    >
      {duties.map((item) => (
        <Col span={12} key={item.id}>
          <h2 className="duties-title">{t(item.title)}</h2>
          <p className="duties-desc">{t(item.description)}</p>
        </Col>
      ))}
    </Row>
  );
};

export default Duties;
