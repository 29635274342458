import { Row, Col, Divider, Table } from "antd";
import { projectSource, columns } from "../data";
import { useTranslation } from "react-i18next";

const Projects = () => {
  const { t } = useTranslation();
  return (
    <Row
      gutter={{ xs: 8 }}
      justify="center"
      align="middle"
      className="project-table"
    >
      <Divider orientation="left" plain>
        <h1 className="about-intro-title">{t("SIDE PROJECTS")}</h1>
      </Divider>
      <Col span={24}>
        <Table
          columns={columns}
          expandable={{
            expandedRowRender: (record) => (
              <p style={{ margin: 0, textAlign: "center" }}>
                {record.description}
              </p>
            ),
            rowExpandable: (record) => record.name !== "Not Expandable",
          }}
          dataSource={projectSource}
          pagination={{ pageSize: 7 }}
        />
      </Col>
    </Row>
  );
};

export default Projects;
