import { useTranslation } from "react-i18next";
import { Button, Space } from "antd";
import {
  DownloadOutlined,
  GithubFilled,
  LinkedinFilled,
  TwitterCircleFilled,
} from "@ant-design/icons";
import frenchResume from "../assets/DALOUGOU-EMMANUEL.pdf";
import englishResume from "../assets/EMMANUEL-DALOUGOU.pdf";

const Header = () => {
  const { t } = useTranslation();
  return (
    <div className="App">
      <header className="App-header">
        <section className="App-title">
          <h1 className="name">👋 {t("Hi. I'm Emmanuel Dalougou")}</h1>
          <Space>
            <code className="_job">{t("Software Developer")}</code>
            <a
              href="https://github.com/emmadal"
              rel="noreferrer"
              target="_blank"
              className="social-network"
            >
              <GithubFilled />
            </a>
            <a
              href="https://linkedin.com/in/edalougou"
              rel="noreferrer"
              target="_blank"
              className="social-network"
            >
              <LinkedinFilled />
            </a>
            <a
              href="https://twitter.com/emmanuel_dal"
              rel="noreferrer"
              target="_blank"
              className="social-network"
            >
              <TwitterCircleFilled />
            </a>
          </Space>
          <div className="resume_btn">
            <a href={englishResume} download={"Emmanuel-Dalougou"}>
              <Button type="default" icon={<DownloadOutlined />} size="large">
                {t("English resume")}
              </Button>
            </a>
            <a href={frenchResume} download={"Dalougou-Emmanuel"}>
              <Button type="link" icon={<DownloadOutlined />} size="large">
                {t("French resume")}
              </Button>
            </a>
          </div>
        </section>
      </header>
    </div>
  );
};

export default Header;
