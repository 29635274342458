export const duties = [
  {
    id: 1,
    title: "Frontend Development",
    description:
      "I develop clear, fast and easy-to-use interfaces with ReactJS library, HTML5/CSS3 and bootstrap. My favorite frameworks is ReactJS.",
  },
  {
    id: 2,
    title: "Desktop Development",
    description:
      "With Electron, I build desktop application for the companies. Initially developed for GitHub's Atom editor.",
  },
];
export const projectSource = [
  {
    key: 1,
    name: "ReactJS French documentation contributor",
    repository: "https://github.com/reactjs/fr.reactjs.org/graphs/contributors",
    technologies: "ReactJS, Javascript",
    description: "French version of the official React documentation site",
    demo: "https://fr.reactjs.org/",
  },
  {
    key: 2,
    name: "Songon Park Hotel Demo",
    repository: "https://github.com/reactjs/fr.reactjs.org/graphs/contributors",
    technologies: "ReactJS, Javascript, Bootstrap, MongoDB, GraphQL",
    description: "Songon Park Hotel demo website",
    demo: "https://songonparkhotel.netlify.app/",
  },
  {
    key: 3,
    name: "GraphQL Server",
    repository: "https://github.com/emmadal/graphql-server",
    technologies: "Javascript, GrahpQL, NodeJS",
    description:
      "This is a small Graphql Server build with appolo-server that allow us to add, delete, update and query the data into the database",
    demo: "https://github.com/emmadal/graphql-server",
  },
  {
    key: 4,
    name: "Deno Books API",
    repository: "https://github.com/emmadal/denoBooksAPI",
    technologies: "Typescript, Deno",
    description: "Deno Books API written in Typescript",
    demo: "https://github.com/emmadal/denoBooksAPI",
  },
  {
    key: 5,
    name: "Port Scan",
    repository: "https://github.com/emmadal/portscan",
    technologies: "Javascript, NodeJS",
    description: "a small Port Scanner written in Javascript",
    demo: "https://github.com/emmadal/portscan",
  },
  {
    key: 6,
    name: "eManagerHouse",
    repository: "https://github.com/emmadal/eManagerHouse",
    technologies: "JQuery, HTML5, CSS3, Bootstrap, ElectronJS",
    description: "Desktop software for Agency house",
    demo: "https://github.com/emmadal/eManagerHouse",
  },
  {
    key: 7,
    name: "Weather App",
    repository: "https://github.com/emmadal/react-weather",
    technologies: "ReactJS, Javascript",
    description:
      "React weather is a project that get the weather in realtime. Made with React",
    demo: "https://myreact-weather.netlify.app/",
  },
  {
    key: 8,
    name: "Deno Mongo",
    repository: "https://github.com/emmadal/denomongo",
    technologies: "Typescript, Deno",
    description:
      "Deno REST API written in Typescript. We're using Oak and MongoDB",
    demo: "https://github.com/emmadal/denomongo",
  },
  {
    key: 9,
    name: "Todo App",
    repository: "https://github.com/emmadal/reactredux",
    technologies: "ReactJS, Javascript, Redux",
    description:
      "A Todo app with persistence built with Redux a predictable state container for javascript applications",
    demo: "https://reactredux.pages.dev",
  },
  {
    key: 10,
    name: "Markdown Previewer",
    repository: "https://github.com/emmadal/markdown-previewer",
    technologies: "ReactJS, Javascript",
    description: "Markdown Previewer",
    demo: "https://mkd-preview.netlify.app/",
  },
  {
    key: 11,
    name: "Music master",
    repository: "https://github.com/emmadal/musicmaster",
    technologies: "ReactJS, Javascript",
    description: "A platfom for search and listening the preview music",
    demo: "https://emmadal.github.io/musicmaster/",
  },
  {
    key: 12,
    name: "Movies web app",
    repository: "https://github.com/emmadal/react-movie-master",
    technologies: "ReactJS, Javascript",
    description: "A website to search, rating, and getting the movies",
    demo: "https://moviemaster.netlify.app/",
  },
  {
    key: 13,
    name: "Javascript Clock",
    repository: "https://github.com/emmadal/javascript-clock",
    technologies: "Javascript, HTML5, CSS3",
    description: "Javascript Clock demo",
    demo: "https://github.com/emmadal/javascript-clock",
  },
];

export const columns = [
  { title: "Name", dataIndex: "name", key: "name" },
  { title: "Repository", dataIndex: "repository", key: "repository" },
  { title: "Technologies", dataIndex: "technologies", key: "technologies" },
  {
    title: "Demo",
    dataIndex: "",
    key: "x",
    render: (content) => (
      <a href={`${content.demo}`} rel="noreferrer" target="_blank">
        View
      </a>
    ),
  },
];

export const skills = [
  {
    id: 1,
    name: "graphql",
    url: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/graphql/graphql-plain-wordmark.svg",
  },
  {
    id: 2,
    name: "javascript",
    url: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg",
  },
  {
    id: 3,
    name: "typescript",
    url: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/typescript/typescript-original.svg",
  },
  {
    id: 4,
    name: "webpack",
    url: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/webpack/webpack-original-wordmark.svg",
  },
  {
    id: 5,
    name: "react",
    url: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original-wordmark.svg",
  },
  {
    id: 6,
    name: "sass",
    url: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/sass/sass-original.svg",
  },
  {
    id: 7,
    name: "bootstrap",
    url: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-plain-wordmark.svg",
  },
  {
    id: 8,
    name: "electron",
    url: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/electron/electron-original.svg",
  },
  {
    id: 9,
    name: "redux",
    url: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/redux/redux-original.svg",
  },
  {
    id: 10,
    name: "denojs",
    url: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/denojs/denojs-original-wordmark.svg",
  },
];
